<template>
  <!-- 账号续费订单 -->
  <div class="box-card">
    <!-- 查询表单 -->
    <el-form :inline="true" size="small" label-position="right" class="query_form" :model="queryForm"
      @submit.native.prevent>
      <el-form-item label="商家号：">
        <el-input maxlength="30" v-model="queryForm.merchant_number" @keyup.enter.native="onSubmit" placeholder="请输入商家号"
          @input="(e) => (queryForm.merchant_number = validNumberLetter(e))"></el-input>
      </el-form-item>
      <el-form-item label="商家名称：">
        <el-input maxlength="30" v-model="queryForm.merchant_name" @keyup.enter.native="onSubmit" placeholder="请输入商家名称"
          @input="(e) => (queryForm.merchant_name = validSpace(e))"></el-input>
      </el-form-item>
      <el-form-item label="支付时间：">
        <el-date-picker v-model="timeduan" value-format="yyyy-MM-dd HH:mm:ss" :default-time="['00:00:00', '23:59:59']"
          @blur="onSubmit" type="datetimerange" range-separator="至" start-placeholder="请选择开始时间" end-placeholder="请选择结束时间">
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <lbButton icon="chaxun" :fill="true" @click="onSubmit()">查询</lbButton>
      </el-form-item>
    </el-form>
    <le-card title="账号续费订单">
      <!-- 主要内容 -->
      <div style="text-align:left;">
        <!-- 表格 -->
        <!-- 使用插件生产表格 -->
        <!-- <avue-crud :data="tableData" :option="option" :page.sync="page" @size-change="sizeChange" @current-change="currentChange">
          <template slot-scope="{row}" slot="valid_time">
            {{ $moment(row.valid_time).format("YYYY-MM-DD") }}
          </template>
          <template slot-scope="{row}" slot="order_price">
            ￥ {{ row.order_price.toFixed(2) }}
          </template>
          <template slot-scope="{row}" slot="payment_method">
            <span>{{ row.payment_method === 0 ? '/' : getWayName("PaymentMethod",
              row.payment_method) }}</span>
          </template>
          <template slot-scope="{row}" slot="payment_time">
            {{ $moment(row.payment_time).format("YYYY-MM-DD HH:mm:ss") }}
          </template>
          <template slot-scope="{row}" slot="menu">
            <lbButton type="succes" icon="chakan" hint="查看" @click="lookAccountOrder(row._id)"></lbButton>
          </template>
        </avue-crud> -->
        <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" border stripe>
          <el-table-column type="selection" width="50" align="center" header-align="center"></el-table-column>
          <el-table-column prop="order_no" label="订单号" width="180"></el-table-column>
          <el-table-column prop="account_package_name" label="套餐包" min-width="180"></el-table-column>
          <el-table-column prop="merchant_name" label="商家名称"></el-table-column>
          <el-table-column prop="merchant_number" label="商家号"></el-table-column>
          <el-table-column label="有效期至">
            <template slot-scope="scope">
              {{ $moment(scope.row.valid_time).format("YYYY-MM-DD") }}
            </template>
          </el-table-column>
          <el-table-column label="订单金额">
            <template slot-scope="scope">
              ￥ {{ scope.row.order_price.toFixed(2) }}
            </template>
          </el-table-column>
          <el-table-column label="支付方式">
            <template slot-scope="scope">
              <span>{{ scope.row.payment_method === 0 ? '/' : getWayName("PaymentMethod",
                scope.row.payment_method) }}</span>
            </template>
          </el-table-column>
          <el-table-column label="支付时间" width="180">
            <template slot-scope="scope">
              {{ $moment(scope.row.payment_time).format("YYYY-MM-DD HH:mm:ss") }}
            </template>
          </el-table-column>
          <el-table-column label="操作" width="100">
            <template slot-scope="scope">
              <lbButton type="succes" icon="chakan" hint="查看" @click="lookAccountOrder(scope.row._id)"></lbButton>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="queryForm.PageIndex" :page-sizes="[10, 20, 30, 40]" :page-size="queryForm.PageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="total" >
        </el-pagination>
      </div>
    </le-card>
    <!-- 查看账号续费订单 -->
    <lebo-dialog append-to-body title="查看账号续费订单" :isShow="showLookAccountOrder" width="70%"
      @close="showLookAccountOrder = false" footerSlot closeOnClickModal>
      <lookAccountOrder @closeDialog="closeAccountOrder" :id="dialogAccountOrderId" v-if="showLookAccountOrder">
      </lookAccountOrder>
    </lebo-dialog>
  </div>
</template>
<script>
import { getAccountOrderPageList, getAccountOrderDetail } from '@/api/discountCoupon'
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
import lookAccountOrder from './components/lookAccountOrder/index.vue'
export default {
  components: { lookAccountOrder },
  data () {
    return {
      timeduan: null,
      queryForm: {
        PageSize: 10,
        PageIndex: 1,
        merchant_number: '',
        merchant_name: '',
        start_time: '',
        end_time: ''
      },
      total: 0,
      // 表格数据
      tableData: [],
      showLookAccountOrder: false,
      dialogAccountOrderId: '',
      // 表格插件配置项
      option: {
        searchMenuSpan: 8,
        header: false,
        delBtn: false, // 行删除按钮
        editBtn: false, // 编辑按钮
        // searchBtn:false,
        menuAlign: 'left', // 属性对齐方式
        menuHeaderAlign: 'left', // 表头对齐方式
        menu: true,
        menuFixed: false, // 解除操作框冻结
        tip: false,
        column: [
          {
            label: '订单号',
            prop: 'order_no'
          }, {
            label: '套餐包',
            prop: 'account_package_name'
          }, {
            label: '商家名称',
            prop: 'merchant_name'
          }, {
            label: '商家号',
            prop: 'merchant_number'
          }, {
            label: '有效期至',
            prop: 'valid_time',
            slot: true
          }, {
            label: '订单金额',
            prop: 'order_price',
            slot: true
          }, {
            label: '支付方式',
            prop: 'payment_method',
            slot: true
          }, {
            label: '支付时间',
            prop: 'payment_time',
            slot: true
          }
        ]
      },
      // 分页器
      page: {
        background: false,
        total: 0,
        pageSize: 10,
        currentPage: 1
      }
    }
  },
  created () {
    if (this.queryStatus === 2) {
      this.queryForm = this.deepClone(this.queryObj)
      this.timeduan = [this.queryForm.start_time, this.queryForm.end_time]
    }
    this.fngetAccountOrderPageList()
  },
  beforeRouteLeave (to, from, next) {
    this.setQueryObj(this.queryForm)
    this.setQueryStatus(1)
    next()
  },
  mounted () {
    window.addEventListener('beforeunload', e => this.beforeunloadFn(e))
  },
  computed: {
    ...mapState(['queryObj', 'queryStatus']),
    ...mapGetters(['isAdmin', 'getOptions', 'getWayName', 'getAllParkingList'])
  },
  watch: {
    'timeduan' (newV, oldV) {
      if (newV) {
        this.queryForm.start_time = newV[0]
        this.queryForm.end_time = newV[1]
      } else {
        this.queryForm.start_time = ''
        this.queryForm.end_time = ''
        this.onSubmit()
      }
    }
  },
  destroyed () {
    window.removeEventListener('beforeunload', e => this.beforeunloadFn(e))
  },
  methods: {
    ...mapMutations(['setQueryObj', 'setQueryStatus']),
    beforeunloadFn (e) {
      this.setQueryStatus(1)
    },
    async fngetAccountOrderPageList () {
      const res = await getAccountOrderPageList(this.queryForm)
      console.log(res)
      this.tableData = res && res.Code === 200 && res.Data ? res.Data.DataList : []
      this.total = res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
      this.page.total = res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
    },
    // 查询
    onSubmit () {
      this.queryForm.PageIndex = 1
      this.fngetAccountOrderPageList()
    },
    // 每页条数改变
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`);
      this.queryForm.PageSize = val
      this.fngetAccountOrderPageList()
    },
    // // 每页条数改变
    // sizeChange (val) {
    //   this.page.currentPage = 1
    //   this.page.pageSize = val
    //   this.queryForm.PageSize = val
    //   this.fngetAccountOrderPageList()
    // },
    // 页码改变
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`);
      this.queryForm.PageIndex = val
      this.fngetAccountOrderPageList()
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0
    },
    // // 页码改变
    // currentChange (val) {
    //   this.queryForm.PageIndex = val
    //   this.page.currentPage = val
    //   this.fngetAccountOrderPageList()
    // },
    lookAccountOrder (id) {
      this.showLookAccountOrder = true
      this.dialogAccountOrderId = id
    },
    closeAccountOrder () {
      this.showLookAccountOrder = false
    }
  }
}
</script>
<style scoped lang="less">
.box-card {
  margin-top: 20px;
}
</style>
